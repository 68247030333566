///////////////////////////////////////
/////////////// IMPORTS ///////////////
///////////////////////////////////////

// Reset

@import url(./scss/Reset.scss);



// Fonts

@import url(./scss/Fonts.scss);



// Components

@import url(./scss/ThemeWrapper.scss);

@import url(./scss/ContactForm.scss);


// Pages

@import url(./scss/ThemesBeely.scss);

@import url(./scss/Blog.scss);

///////////////////////////////////////
/////////////// COLORS ////////////////
///////////////////////////////////////

// White/Grey/Cream

$white: #ffffff;
$white-transparent: #ffffffab;
$grey: #5C6680;
$cream: #f1f1f1;
$cream-p: #c8c8c8;


/*// Black/Dark

$primary: #8C358B;
$secondary: #190c1c;
$terciary: #241228;
$dark: #4c234b;
$opacity: #4C234B60;
$without-opacity: #2E152F;
$primary-hover: #6e216d;
// $terciary: #230a29;

$black: #0A1929;
$black-p: #3e5060;
$dark-transparent: #190c1cab;
$dark: #4c234b;
$text-light: #000000cc;
$border-card: #5c668034;


// Colors

$purple: #8C358B;
$purple-transparent: #4c234b;
$pink: #f3d9f3;
$pink-transparent: #f9ebf9;*/



// Black/Dark

$primary: #0057FF;
$secondary: #190c1c;
$terciary: #241228;
$dark: #4c234b;
$opacity: #4C234B60;
$without-opacity: #2E152F;
$primary-hover: #003ecb;
// $primary-hover: #00174d;
// $terciary: #230a29;

$black: #0A1929;
$black-p: #3e5060;
$dark-transparent: #190c1cab;
$dark: #4c234b;
$text-light: #000000cc;
$border-card: #5c668034;


// Colors

$purple: #1e69fe;
$purple-transparent: #4c234b;
$pink: #dee8ff;
$pink-transparent: #f5f8ff;



// DARK/LIGHT MODE

// COLORS - Texts

.lightTheme {

  & p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  span,
h2 span.txtNoWrap,
h3 span.txtNoWrap {
    color: $white;
  }

  & .subTitleSection {
    color: $purple;
  }

  & .titleSection,
  h2.titleSection span.txtNoWrap,
  h3.titleSection span.txtNoWrap {
    color: $black;
  }
}

// COLORS - Elements

.lightTheme {
  background-color: $white;
}

.lightTheme {
  & .button2 {
    color: $purple !important;
    background-color: $pink;
    border-color: $pink;
  }

  & a {
    color: $primary;
  }

  & p a:hover,
  .sitemapPage .sitemap a:hover,
  footer .FooterNavList a:hover {
    color: $primary !important;
    text-decoration: underline;
  }
}

// COLORS - Section

.lightTheme {

  & .separatorSections,
  .separatorSectionsMini {
    background-color: $border-card;
  }
}

// COLORS - Skeleton

.lightTheme {

  & .article-card,
  .skeletonSites .sectionSites {
    background-color: $white;
    box-shadow: rgba(90, 105, 120, 0.083) 1px 1px 10px 0px;
    border: 1px solid $border-card;
  }

  & .skeleton {
    background-color: $white;
  }

  & .skeleton-title-page,
  .skeleton-title-category,
  .skeleton-btn-plus-category,
  .skeleton-category,
  .skeleton-image,
  .skeleton-name-header,
  .skeleton-image-profile-header,
  .skeleton-image-profile,
  .skeleton-title-profile,
  .skeleton-btn-profile,
  .skeleton-subtitle,
  .skeleton-input,
  .skeleton-title-sites,
  .skeleton-content-sites,
  .skeleton-button-sites,
  .skeleton-title,
  .skeleton-description,
  .skeleton-btn,
  .skeleton-btn2 {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #b1b8c6;
    background-image: linear-gradient(to right, #b1b8c6 0%, #c9cfdf 20%, #b1b8c6 40%, #b1b8c6 100%);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

// COLORS - Header

.lightTheme {
  & .header {
    background-color: rgba(255, 255, 255, 0.649);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
    // border-bottom: 1px solid $border-card;
    border: none;
  }

  & .header p {
    color: $black-p;
  }

  & .logoHeader,
  .logoHeaderDashboard {
    filter: none;
  }

  & .labelNavigation {
    color: $black;
  }

  & .header .navigation .navigationList:hover .navigationLink,
  .linkHeaderMegaMenu:hover {
    background: $pink;
  }

  & .header .iconDown {
    color: $black;
  }

  & .header .megaMenu:not(.header .sectionMenuMobile .megaMenu) {
    background-color: $white;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
    border-color: $border-card;
  }

  & .header .sectionMenuMobile .megaMenu {
    background-color: $white;
    border-color: $pink !important;
  }

  & .header .megaMenu h2,
  .header .megaMenu h3,
  .header .megaMenu div {
    color: $black;
  }

  & .linkHeaderMegaMenu {
    border-color: $border-card;
  }

  & .buttonCategorie {
    background: $pink;
    color: $purple;
  }

  & .themeWrapper .switch,
  .miniBtn,
  .footerSocials a {
    border-color: $border-card;
  }

  & .themeWrapper .switch:hover,
  .buttonMenuBurger:hover,
  .button2:hover,
  .miniBtn:hover,
  .footerSocials a:hover {
    background-color: $pink;
  }

  & .buttonMenuBurger {
    background: transparent;
    border-color: $border-card;
  }

  & .buttonMenuBurger svg {
    color: $primary;
  }

  & .sectionMenuMobile {
    background-color: $white;
  }

  & .questionsHeaderMobile {
    background-color: $pink-transparent;
  }

  & .questionsHeaderMobile {
    background-color: $pink;
  }

  & .questionsHeaderMobile h2,
  .questionsHeaderMobile h2 span.txtNoWrap {
    color: $primary;
  }

  & .footerBottom,
  .copyright {
    border-color: $border-card;
  }
}

// COLORS - Footer

.lightTheme {

  & .logoFooter,
  .logoCopyright,
  .logoHeaderLeftDashboard {
    filter: none;
  }

  & footer p,
  footer a,
  footer span,
  footer h1,
  footer h2,
  footer h3,
  footer h4,
  footer h5,
  footer h6 {
    color: $black-p;
  }

  & footer .footerNavTitle {
    color: $black;
  }

  & #scrollTopHeaderArrow {
    background: $white;
    border-color: $border-card;
  }

  & #scrollTopHeaderArrow:hover {
    background: $pink;
  }
}

// COLORS - Cookies

.lightTheme {

  & #CookiebotWidget *,
  #CookiebotWidget .CookiebotWidget-consent-details button,
  #CybotCookiebotDialog *,
  #CybotCookiebotDialogBodyUnderlay *,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    color: $black-p !important;
  }

  & #CookiebotWidget .CookiebotWidget-header .CookiebotWidget-close svg {
    fill: $black-p !important;
  }

  & #CybotCookiebotDialog button span.CybotCookiebotDialogDetailBulkConsentCount {
    color: $white !important;
  }

  & #CookiebotWidget #CookiebotWidget-widgetContent,
  #CybotCookiebotDialog,
  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw,
  #CookiebotWidget .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
    background: $white !important;
  }

  & #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg,
  #CookiebotWidget .CookiebotWidget-consent-details button svg {
    fill: $black-p !important;
  }

  & #CookiebotWidget,
  #CookiebotWidget .CookiebotWidget-header,
  #CybotCookiebotDialog .CookieCard,
  #CybotCookiebotDialog .CybotCookiebotScrollContainer,
  #CybotCookiebotDialogBodyLevelButtonsSelectPane,
  #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogNav,
  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    background-color: $white !important;
    border-color: $border-card !important;
  }

  & #CookiebotWidget .CookiebotWidget-logo svg circle,
  #CookiebotWidget:hover .CookiebotWidget-logo svg circle {
    fill: transparent !important;
  }

  & #CookiebotWidget:hover {
    background: $pink !important;
  }

  & #CookiebotWidget:not(.CookiebotWidget-open):hover {
    box-shadow: none !important;
  }
}

// COLORS - Home - Hero

.lightTheme {

  & h1.titlePages {
    color: $black;
  }

  & .buttonOrderWebsiteHomeBeely,
  .buttonOrderWebsiteHomeBeely span,
  .descriptionsHeroHome .descriptionHeroHome svg,
  .descriptionPages.descriptionHeroHomeBeely {
    color: $black-p;
  }

  & .arrowDown svg {
    color: $purple;
  }

  & .subHeroRightBottom {
    background-color: white;
  }

  & .heroRightBeely {
    background: $pink;
  }

  & .cookieBanner {
    background: white;
    border: 1px solid $dark;
  }

  & .cookieManageButton button {
    background: $secondary;
  }

  & .cookieButtons .miniBtn {
    background: transparent;
  }

  body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid $border-card;
    color: #1e69fe;
  }

  & body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
  body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
  body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
  body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: white !important;
    border-color: $border-card !important;
    color: $white !important;
  }

  & body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
  body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border-color: $border-card !important;
    color: $white !important;
  }

  & body #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border: 2px solid $border-card !important;
    color: $white !important;
  }

  & body #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
  body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: $primary !important;
  }

  & body #CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider,
  body #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    background-color: white !important;
  }

  & body #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    fill: $white !important;
  }

  & body #CybotCookiebotDialog *,
  body #CybotCookiebotDialogBodyUnderlay *,
  body #CookiebotWidget .CookiebotWidget-consent-details button {
    color: $white !important;
  }

  & body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
    background-color: white !important;
    border-color: $border-card !important;
  }

  & body #CybotCookiebotDialog,
  body #CookiebotWidget #CookiebotWidget-widgetContent {
    background: white !important;
  }

  & body #CybotCookiebotDialogHeader,
  body #CybotCookiebotDialogNav,
  body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink,
  body #CybotCookiebotDialog .CookieCard,
  body #CybotCookiebotDialog .CybotCookiebotScrollContainer,
  body #CybotCookiebotDialogBodyLevelButtonsSelectPane,
  body #CookiebotWidget .CookiebotWidget-header {
    border-bottom: 1px solid $border-card !important;
  }

  & body #CookiebotWidget .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
    background-color: white !important;
  }

  & body #CookiebotWidget #CookiebotWidget-buttons {
    border-top: 1px solid $border-card !important;
  }

  & body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
    background: white !important;
  }

  & body #CookiebotWidget {
    background: white !important;
    border: 1px solid $border-card !important;
  }

  & body #CookiebotWidget:hover {
    background: white !important;
  }

  & body #CookiebotWidget:hover .CookiebotWidget-logo svg circle {
    fill: white !important;
  }

  & body #CookiebotWidget .CookiebotWidget-logo svg circle {
    fill: $secondary !important;
  }

  & body #CookiebotWidget .CookiebotWidget-logo svg path {
    fill: $primary !important;
  }

  & body .CybotCookiebotFader {
    background-image: none !important;
  }

  @media screen and (min-width: 601px) {
    & body #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type) {
      border-right: 1px solid $border-card !important;
    }
  }
}

// COLORS - Home - Steps

.lightTheme {

  & button.btnSteps,
  .sectionStepLeft {
    background: $pink-transparent;
  }

  & button.btnSteps h3,
  .sectionStepLeft h4,
  .sectionStepLeft p {
    color: $black;
  }

  & button.btnSteps:hover {
    border-color: $pink-transparent;
  }
}

// COLORS - Home - Different Offers

.lightTheme {
  & .differentOffers {
    background-color: $pink;
  }

  & .differentOffers h2 {
    color: $primary;
  }

  & .differentOffers .subTitleSection {
    color: $black-p;
  }

  & .websiteOffer .cardTop {
    background-color: $primary;
  }

  & .websiteOffer,
  .websiteOffer .cardBottom {
    background-color: $white;
    border: none;
  }

  & .websiteOffer .cardBottom .button1 span,
  .websiteOffer .cardBottom span.advantageOffer {
    color: $white;
  }

  & .websiteOffer .cardTop span,
  .websiteOffer .cardTop p {
    color: $white;
  }

  & .websiteOffer .cardBottom h3,
  .websiteOffer .cardBottom span,
  .websiteOffer .cardBottom p {
    color: $black-p;
  }

  & .websiteOffer .cardTop h3 {
    color: $white;
  }

  & .iconSpecOffer svg:not(.iconSpecOffer svg.specsNone) {
    color: green;
  }

  & .asterisque.homeAsterisque {
    color: $black-p;
  }
}

// COLORS - Home - FormTypeSite

.lightTheme {
  & .whatOffer .descriptionWhatOffer {
    color: $black-p;
  }

  & .whatOffer .formTypeSite {
    border-color: $border-card;
  }

  & .whatOffer h3,
  .whatOffer h4,
  .whatOffer h2 span.txtNoWrap,
  .whatOffer h3 span.txtNoWrap {
    color: $black;
  }

  & .whatOffer svg {
    color: $primary;
  }
}

//COLORS - Home - Table offers

.lightTheme {

  & .tableBeely.tableDesktopBeely p,
  .tableBeely.tableDesktopBeely span,
  .tableBeely.tableDesktopBeely h1,
  .tableBeely.tableDesktopBeely h2,
  .tableBeely.tableDesktopBeely h3,
  .tableBeely.tableDesktopBeely h4,
  .tableBeely.tableDesktopBeely h5,
  .tableBeely.tableDesktopBeely h6,
  .tableBodyTrBeely p,
  .tableBodyTrBeely span,
  .tableBodyTrBeely h1,
  .tableBodyTrBeely h2,
  .tableBodyTrBeely h3,
  .tableBodyTrBeely h4,
  .tableBodyTrBeely h5,
  .tableBodyTrBeely h6 {
    color: $black-p;
  }

  & .tableBeely.tableDesktopBeely .titleHeaderTitleBeely,
  .tableBeely.tableDesktopBeely .tableBodyHeaderBeely span,
  .tableBodyHeaderBeely span {
    color: $black;
  }

  .tableBeely.tableDesktopBeely .button1 span {
    color: $white;
  }

  & .tableDesktopBeely .sectionTableHeaderBeely:nth-child(2),
  .tableDesktopBeely .tableBodyTdBeely:nth-child(2) {
    background-color: $pink-transparent;
    border-color: $pink-transparent;
  }

  & .headerTableStickyBeely {
    background-color: $primary;
    border-bottom: 1px solid $primary;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  }

  & .headerTableStickyBeely .titleHeaderTableStickyBeely span {
    color: $white;
  }

  & .headerTableStickyBeely .button1 {
    background-color: $pink;
    color: $primary;
  }

  & .headerTableStickyBeely .titleHeaderTableStickyBeely a.button1 span {
    color: $primary;
  }

  & .tableBeely.tableDesktopBeely .recommandedNotice {
    background-color: $purple;
    border-color: $purple;
    color: $white;
  }

  & .tableBodyTdBeely svg {
    color: green;
  }

  & .tableBodyHeaderBeely {
    background-color: $pink;
  }

  & .tableBodyBeely .tableBodyTrBeely:not(.tableBodyBeely .tableBodyTrBeely:last-child) {
    border-color: $border-card;
  }

  & .headerTablesMobileBeely {
    background-color: $pink-transparent;
    border-color: $pink;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  }

  & .headerTablesMobileBeely span {
    color: $primary;
  }

  & .tableMobileBeely .sectionTableHeaderBeely {
    background-color: $primary;
  }

  & .tableMobileBeely .sectionTableHeaderBeely .button1 {
    background: $pink;
  }

  & .tableMobileBeely .sectionTableHeaderBeely .button1 span,
  & .tableMobileBeely .sectionTableHeaderBeely .button1 svg {
    color: $primary;
  }
}

// COLORS - Home - Faq


.lightTheme {

  & .bottomLeftFaqHome,
  .rightFaqHome {
    background-color: $pink-transparent;
  }

  & .bottomLeftFaqHome h3,
  .rightFaqHome h2,
  .rightFaqHome h3,
  .rightFaqHome p,
  .bottomLeftFaqHome h2 span.txtNoWrap,
  .bottomLeftFaqHome h3 span.txtNoWrap {
    color: $black;
  }

  & .rightFaqHome .tabTitle {
    background-color: $white;
  }
}

// COLORS - Forms

.lightTheme {
  & .formPage {
    background: $purple;

    & .returnHomeForm {
      color: $purple;
      background: $pink-transparent;
      border-color: $pink;
    }

    & .descriptionsHeroHome * {
      color: $white;
    }
  }

  & .fullForm {
    background: $pink-transparent;

    & .loaderForm {
        color: $purple;
    }
  }
}

@media (max-width: 767px) {
  .formPage .returnHomeForm {
    color: $purple !important;
  }
}


// CONTACT FORM

.lightTheme {

  & .popUpContactForm {
    background: $white;
  }

  & .headercontactform {
    background: $primary;
  }

  & .headercontactform h2 {
    color: $white;
  }

  & .btnCloseContactForm {
    border-color: $white;
  }

  & .btnCloseContactForm:hover {
    border-color: $primary;
  }

  & .btnCloseContactForm:hover svg {
    color: $primary;
  }

  & label {
    color: $black !important;
  }

  & .popUpContactForm p,
  .sectionContactForm a {
    color: $black-p;
  }

  & p.error,
  p.success,
  p.error.success {
    color: $white;
  }

  & form label input:not(input[type=radio]),
  form textarea,
  form input,
  input[type=text],
  input[type=textarea],
  form,
  .linksDashboardBeely .cardPage.cardNotes textarea,
  summary::before {
    border-color: $black;
    color: $black-p !important;
  }

  & .sectionSupport form .radioSupport label,
  label.inputSelectTheme p,
  label.labelInputWhatOffer p {
    background-color: $pink;
    border: 1px solid $pink;
  }

  & .sectionSupport form .radioSupport label p,
  label.inputSelectTheme p,
  label.labelInputWhatOffer p {
    color: $primary;
  }

  & .checked,
  label.inputSelectTheme input:checked+p {
    background-color: $primary !important;
  }

  & .checked p,
  label.inputSelectTheme input:checked+p,
  label.labelInputWhatOffer input:checked+p {
    color: $white !important;
  }

  & .switchPrices {
    background: $pink-transparent;
    border-color: $pink;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  }

  & .switchPrices .switchPrice {
    color: $primary;
  }

  & input#legals {
    border-color: $black;
  }
}


// COLORS - Pages

.lightTheme {

  & .heroLeftBeely h1 span,
  .heroLeftBeely h1 b span {
    color: $black;
  }

  & .heroRightBeely .scroller img {
    border-color: $white;
  }

  & .titleSubPages,
  .titleTabFaq {
    color: $black;
  }

  & .heroPages p,
  .heroPages span,
  .heroPages h2,
  .heroPages h2 span.txtNoWrap,
  .heroPages h3 span.txtNoWrap,
  .heroPages h3,
  .heroPages h4,
  .heroPages h5,
  .heroPages h6,
  .pageBlog p:not(.tagBlogHelp),
  .sectionFaqTabs p,
  .contentResponseFaq p,
  .buttonTabFaq,
  .tabContent,
  .tabsGlossary p,
  .tabsGlossary span,
  .tabsGlossary a,
  .legalPage p,
  .legalPage span,
  .legalPage a,
  .legalPage div {
    color: $black-p;
  }

  & .cardPage h2,
  .cardPageTarif h2,
  .tabsGlossary h2,
  .tabsGlossary h3,
  .tabsGlossary h4,
  .pageBlog h1,
  .pageBlog h2,
  .pageBlog h3,
  .pageBlog h4,
  .pageBlog h5,
  .pageBlog h6,
  .legalPage h1,
  .legalPage h2,
  .legalPage h3,
  .legalPage h4 {
    color: $black;
  }

  & a.button1 {
    color: $white;
  }

  & .cardPage a,
  .cardPage p,
  .cardPageTarif svg,
  .cardPageTarif a,
  .cardPageTarif p {
    color: $black-p;
  }

  & .heroPages .avantagesServices .avantageService,
  .buttonTabFaq:hover,
  .tabTitle,
  .buttonsTabsGlossary {
    background-color: $pink;
    border-color: $pink;
  }

  & .heroPages .avantageService span,
  .heroPages .avantageService svg,
  .buttonTabFaq:hover,
  .buttonTabFaq:hover svg,
  .contentResponseFaq svg,
  .tabTitle,
  .buttonsTabsGlossary div {
    color: $primary;
  }

  & .heroPages .button1 span,
  .buttonsTabsGlossary .buttonTabGlossary:hover {
    color: $white;
  }

  & .loaderDashboard,
  .loaderPages {
    background-color: rgba(255, 255, 255, 0.649);
    backdrop-filter: blur(10px);
  }

  & .sectionPage404 {
    background-color: $pink;
  }

  & .sectionPage404 .sectionLeft404 {
    background-color: $white;
  }

  & .sectionPage404 .sectionLeft404 h1 {
    color: $black;
  }

  & .sectionPage404 .sectionLeft404 h2 {
    color: $black-p;
  }

  & .sectionFaqTabs:not(.faqHome .sectionFaqTabs) {
    background: $white;
  }
}


// COLORS - Tarifs

.lightTheme {

  & .headerStickyServices {
    background-color: $primary;
    border-bottom: 1px solid $primary;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  }

  & .headerStickyServices .button1 {
    background-color: $pink;
    color: $primary;
  }

  & .tarifPage h2 {
    color: black;
  }

  & .topHeroRightBeely {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 25%, rgba(255, 255, 255, 0) 100%);
  }

  & .bottomHeroRightBeely {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 25%, rgba(255, 255, 255, 0) 100%);
  }

  & .avantagesServices .avantageService {
    background-color: $pink;
    border-color: $pink;
  }

  & .avantageService span,
  .avantageService svg {
    color: $primary;
  }

  & .descriptionServicePage h2,
  .descriptionServicePage h3 {
    color: $black;
  }

  & .descriptionServicePage p {
    color: $black-p;
  }

  & .descriptionServicePage .rightDescriptionServicePage img {
    // box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
    border-color: $pink;
  }
}


//COLORS - Templates

.lightTheme {
  & .cardTheme {
    // background: $pink;
    background: $white;
    border: none;
  }

  & .cardTheme h2 {
    // color: $primary;
    color: $black;
  }

  & .cardTheme p {
    // color: $black;
    color: $black-p;
  }
}


// COLORS - Blog
.lightTheme {
  & .cardPage.cardCategorie {
    background-color: $pink !important;
    color: $primary;
    border-color: $pink;
  }

  & .cardPage.cardCategorie:hover {
    border: 1px solid $pink !important;
  }

  & .postBlogHelp {
    background: $white;
    border: 1px solid $border-card;
  }

  & .postBlogHelp:hover {
    border: 1px solid $border-card;
  }

  & .postBlogHelp h3 {
    color: $black;
  }

  & .postBlogHelp p.excerptBlogHelp,
  .postBlogHelp p.excerptBlogHelp p {
    color: $black-p;
  }

  & .btnCategorieHelp ul li a.linkArticleHelp.linkActive svg,
  .btnCategorieHelp ul li a.linkArticleHelp.linkActive p {
    color: $purple;
  }
}



// COLORS - Account

.lightTheme {

  & .sectionPageAccountLeft {
    background-image: none;
    background-color: $pink;
  }

  & .sectionPageAccountRight {
    background: $white;
  }

  & .sectionPageAccountRight h1,
  .sectionPageAccountRight h2,
  .sectionPageAccountRight h3,
  .sectionPageAccountRight h4,
  .sectionPageAccountRight h5,
  .sectionPageAccountRight h6 {
    color: $black;
  }

  & .sectionPageAccountRight p,
  .sectionPageAccountRight span,
  .sectionPageAccountRight label,
  .sectionPageAccountRight a {
    color: $black-p;
  }

  & input:not(.inputSelectTheme input):not(.inputSearchBar),
  body form textarea,
  .sectionSupport form textarea,
  .sectionContactForm form textarea,
  input {
    border-color: $border-card !important;
  }
}


// COLORS - Dashboard

.lightTheme {

  & .headerLeftDashboard,
  .sectionPageDashboardOrder {
    background-color: $white;
  }

  & .headerTopDashboard,
  .stickyHeaderLeftDashboard {
    background-color: rgba(255, 255, 255, 0.649);
  }

  & .headerTopDashboard,
  .headerLeftDashboard {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  }

  & .headerLeftDashboard .buttonMenuBurger .closeMenuMobile {
    color: $primary !important;
  }

  & .sectionPageDashboard {
    background: $white;
  }

  & .headerLeftDashboardDesktop {
    border-right: none;
  }

  & .headerLeftDashboard a,
  .headerLeftDashboard p,
  .headerLeftDashboard svg,
  .headerLeftDashboard span,
  .sectionPageDashboard a,
  .sectionPageDashboard p,
  .sectionPageDashboard span,
  .sectionPageDashboard svg,
  .sectionPageDashboard h1,
  .sectionPageDashboard h2,
  .sectionPageDashboard h3,
  .sectionPageDashboard h4,
  .sectionPageDashboard h5,
  .sectionPageDashboard h6 {
    color: $text-light;
  }

  .themeWrapper svg {
    color: $primary;
  }

  & .profileHeaderDashboard.headerLeft,
  .descriptionHeaderLeft,
  .headerLeftDashboard .linksDashboard:hover {
    background: $pink;
  }

  & .profileHeaderDashboard.headerLeft svg,
  .profileHeaderDashboard.headerLeft p,
  .descriptionHeaderLeft svg,
  .descriptionHeaderLeft span,
  .headerLeftDashboard .linksDashboard:hover svg,
  .headerLeftDashboard .linksDashboard:hover span {
    color: $primary;
  }

  & .cardPage,
  .cardPageTarif,
  input.inputSearchBar {
    background: $white !important;
    border: 1px solid $border-card;
    box-shadow: none;
  }

  & .cardPage:hover,
  .cardPageTarif:hover {
    border: 1px solid $border-card !important;
  }

  & .sectionProfile,
  .sectionSupport,
  table,
  .orderThemes,
  .formOrders {
    background: $white;
    box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
    border: none;
  }

  & table thead {
    background-color: $pink;
  }

  & table tbody tr:nth-child(odd) {
    background: $white;
  }

  & table thead,
  table tbody td {
    border-color: $border-card;
  }

  & .sectionPageDashboardOrder .differentOffers {
    background: $white;
  }

  & .sectionPageDashboardOrder .differentOffers h1 {
    color: $primary;
  }

  & .sectionPageDashboardOrder .differentOffers .websiteOffer {
    // border: 1px solid $border-card;
    box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
  }

  & .sectionAboutProfile,
  .sectionSecurityProfile,
  .orderThemes {
    background: $pink;
  }

  & .sectionAboutProfile h1,
  .sectionSecurityProfile h2 {
    color: $primary;
  }

  & .orderThemes .button1 {
    color: $white;
  }

  & .orderThemes svg,
  .orderThemes p {
    color: $primary;
  }

  & .divFormProfile:not(.divFormProfile.lastInput),
  .divFormSecurityProfile {
    border-color: $border-card;
  }

  & .ordersPage .formPrice .priceForm {
    color: $primary;
  }

  & .questionForm {
    border-color: $primary;
  }

  & .formOrders input:not(.inputSelectTheme input),
  .formOrders textarea {
    border-color: $black-p;
  }

  & .button1 span,
  .button1 svg,
  .button1.buttonHero,
  .button1.buttonHero svg,
  .searchBar button span,
  .searchBar button svg,
  .plusPage svg {
    color: $white;
  }

  & .button2.logOutButton {
    border-color: $border-card;
  }

  & .button2.logOutButton:hover {
    background-color: $pink;
  }

  & .cardMySiteBeely {
    background-color: $pink;
  }

  & .cardMySiteBeely h2 {
    color: $primary;
  }

  & .cardMySiteBeely .buttonsCardsMySites a.button1 {
    color: $white;
  }

  & table .button1 p {
    color: $white;
  }

  & .sectionPageDashboard.sectionPageDashboardSuccess a.button1 {
    color: $white;
  }
}


// COLORS - Centre d'aide

.lightTheme {

  & .headerTopHelp h1,
  .headerTopHelp h2,
  .headerTopHelp h3,
  .headerTopHelp h4,
  .headerTopHelp h5,
  .headerTopHelp h6,
  .headerLeftHelp h1,
  .headerLeftHelp h2,
  .headerLeftHelp h3,
  .headerLeftHelp h4,
  .headerLeftHelp h5,
  .headerLeftHelp h6,
  .fixedReturnToBeely svg,
  .fixedReturnToBeely p,
  .sectionPageHelp h1,
  .sectionPageHelp h2,
  .sectionPageHelp h3,
  .sectionPageHelp h4,
  .sectionPageHelp h5,
  .sectionPageHelp h6 {
    color: $black;
  }

  & .headerTopHelp p,
  .headerTopHelp div,
  .headerLeftHelp p,
  .headerLeftHelp span,
  .headerLeftHelp div,
  .headerLeftHelp a,
  .btnCategorieHelp ul li .linkArticleHelp svg,
  .inputSearchBar,
  .sectionPageHelp p,
  .sectionPageHelp span,
  .sectionPageHelp a,
  .sectionPageHelp div {
    color: $black-p;
  }

  & .categorieHelp:hover,
  .btnCategorieHelp ul li .linkArticleHelp:hover {
    background: $pink;
    color: $primary;
  }

  & .categorieHelp:hover h2,
  .categorieHelp:hover svg,
  .btnCategorieHelp ul li .linkArticleHelp:hover p,
  .btnCategorieHelp ul li .linkArticleHelp:hover svg {
    color: $primary;
  }

  // & .sectionPageHelp {
  //   background-color: rgb(245, 245, 245);
  // }

  & .containerContactHelp,
  .containerContactHelp h3 {
    background: $pink;
    color: $primary;
    border-color: $pink;
  }

  & .containerContactHelp p {
    color: $black-p;
  }

  & .containArticleHelp img {
    box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
  }
}



// COLORS - Plan du site

.lightTheme {

  & .sitemapPage h2,
  .sitemapPage li,
  .sitemapPage li a,
  .sitemapPage li svg {
    color: $black-p;
  }
}






///////////////////////////////////////
//////////////// BODY /////////////////
///////////////////////////////////////

// Body

body {
  background: $secondary;

  &.lightTheme {
    background: $white;
  }
}



// Texts

p,
a,
span,
h5,
h6,
span,
button,
div {
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  // color: #B2BAC2 !important;
  color: $cream;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
span,
li,
button,
td,
th,
div {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: $cream;
  font-weight: 500;
  line-height: normal;
}

textarea,
input {
  font-family: 'Plus Jakarta Sans', sans-serif;
  ;
  color: $cream;
  font-weight: 500;
  line-height: normal;
  font-size: 12px !important;
}

a {
  cursor: pointer;
}

p a:hover,
.sitemapPage .sitemap a:hover,
footer .FooterNavList a:hover {
  color: $primary !important;
  text-decoration: underline;
}

p,
a {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: $cream-p;
  font-weight: 500;
  line-height: normal;
  font-size: 15px;
}

.footerBottomLeft p,
.FooterNavList a,
.copyright span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

h1,
.differentOffers h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  ;
  font-weight: 900;
  font-size: 24px;
  color: $cream;
}

h2,
h3,
h2 span.txtNoWrap,
h3 span.txtNoWrap {
  font-family: 'Plus Jakarta Sans', sans-serif;
  ;
  font-weight: 900;
  font-size: 18px;
  color: $cream;
}

.legalPage h3 {
  font-size: 14px;
}

.footerBottomNavigation h3 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 900;
  font-size: 14px;
}

h4 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  ;
  font-size: 14px;
  font-weight: 700;
}

span.txtNoWrap {
  white-space: nowrap;
  color: currentColor;
}

.lowercase {
  text-transform: lowercase;
  font-size: inherit;
  font-family: inherit;
}

.subTitleSection {
  font-size: 14px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.subTitleSection:not(.differentOffers .subTitleSection) {
  font-weight: 700;
}

.titleSection,
.titleSection span.txtNoWrap {
  margin-top: 0px;
  font-size: 34px;
  margin-bottom: 30px;
}

.titleSection {
  max-width: 750px;
}

.rightFaqHome h2:not(.titleTabFaq) {
  font-size: 34px;
}

@media (max-width: 778px) {

  h1,
  .differentOffers h1 {
    font-size: 18px;
  }

  h2,
  h3,
  .questionsHeaderMobile h2,
  .footerTop h2,
  .titleTabGlossary,
  .glossaryLetter h3,
  .titleSubPages {
    font-size: 18px;
  }

  .legalPage h2 {
    font-size: 16px;
  }

  .legalPage h2 {
    font-size: 14px;
  }

  h4,
  .glossaryDefinition h4 {
    font-size: 13px;
  }


  .sectionStepLeft h4,
  .cardPage h2 {
    font-size: 15px;
  }

  p:not(p.descriptionHeroHomeBeely),
  a,
  span,
  h5,
  h6,
  span,
  button,
  div,
  .sectionStepLeft p,
  .tabTitle,
  .cardPage p {
    font-size: 13px;
  }

  .subTitleSection {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .titleSection,
  .rightFaqHome h2:not(.titleTabFaq),
  h2.titleSection span.txtNoWrap,
  .questionsHeaderMobile h2,
  .questionsHeaderMobile h2 span.txtNoWrap {
    font-size: 18px !important;
  }
}



// Buttons

.button1 {
  background-color: $purple;
  border: none;
  border-radius: 5px;
  min-height: 40px;
  color: $white;
  padding: 11px 20px;
  font-size: 13px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.button1:hover {
  background: $primary-hover;
}

.button1 svg,
.button2 svg {
  min-width: 18px;
  width: 18px;
  max-width: 18px;
}

.button1 span {
  font-size: 13px;
}

.button1.loginButton {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.button2 {
  background-color: transparent;
  border: 1px solid $dark;
  border-radius: 5px;
  min-height: 40px;
  color: $white;
  padding: 10px 20px;
  font-size: 13px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.button2:hover,
.miniBtn:hover {
  background-color: $opacity;
}

.button3 {
  background-color: transparent;
  cursor: pointer;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.button1 span,
.button2 span,
.button3 span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.miniBtn {
  min-height: 34px;
  height: 34px;
  max-height: 34px;
  min-width: 34px;
  width: 34px;
  max-width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $dark;
  border-radius: 5px;
}

.miniBtn svg {
  width: 20px;
  color: $primary;
}


// Icônes

.iconHeader {
  color: $purple;
  min-width: 20px;
  max-width: 20px;
}

.header .iconDown {
  color: white;
  min-width: 15px;
  max-width: 15px;
}


// ContactForm

.popUpContactForm {
  background: $without-opacity;
}

.headercontactform {
  background: $primary;
}

.btnCloseContactForm {
  border-color: $white;
}

.btnCloseContactForm svg {
  color: $white;
}

// Cookie Banner

#CookiebotWidget {
  box-shadow: none !important;
}

.cookieBanner {
  background: $without-opacity;
  border: 1px solid $dark;
}

.cookieManageButton button {
  background: $secondary;
}

.cookieButtons .miniBtn {
  background: transparent;
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid #1e69fe;
  color: #1e69fe;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #1e69fe !important;
  border-color: #1e69fe !important;
  color: $white !important;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  border-color: #1e69fe !important;
  color: $white !important;
}

body #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 2px solid #1e69fe !important;
  color: $white !important;
}

body #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: $primary !important;
}

body #CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider,
body #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
  background-color: #1e69fe !important;
}

body #CybotCookiebotDialogHeader,
body #CybotCookiebotDialogHeaderLogosWrapper,
body #CybotCookiebotDialogPoweredByText a,
body #CybotCookiebotDialogDetailFooter,
body #CybotCookiebotDialogPoweredByText,
body #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}

body #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: $white !important;
}

body #CybotCookiebotDialog *,
body #CybotCookiebotDialogBodyUnderlay *,
body #CookiebotWidget .CookiebotWidget-consent-details button {
  color: $white !important;
}

body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
  background-color: $secondary !important;
  border-color: $primary !important;
}

body #CybotCookiebotDialog,
body #CookiebotWidget #CookiebotWidget-widgetContent {
  background: $secondary !important;
}

body #CybotCookiebotDialog.CybotMultilevel.CybotCookiebotDialogActive,
body #CookiebotWidget.CookiebotWidget-open {
  box-shadow: 10000px 10000px 10px 20000px rgba(0, 0, 0, .7) !important;
}

body #CybotCookiebotDialogHeader,
body #CybotCookiebotDialogNav,
body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink,
body #CybotCookiebotDialog .CookieCard,
body #CybotCookiebotDialog .CybotCookiebotScrollContainer,
body #CybotCookiebotDialogBodyLevelButtonsSelectPane,
body #CookiebotWidget .CookiebotWidget-header {
  border-bottom: 1px solid $dark !important;
}

body #CookiebotWidget .CookiebotWidget-consent-details .CookiebotWidget-consent-details-box {
  background-color: #241228 !important;
}

body #CookiebotWidget #CookiebotWidget-buttons {
  border-top: 1px solid $dark !important;
}

body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
  background: $without-opacity !important;
}

body #CookiebotWidget {
  left: initial !important;
  right: 10px !important;
}

body #CookiebotWidget {
  background: $secondary !important;
  border: 1px solid $dark !important;
  border-radius: 5px !important;
  min-width: 34px !important;
  // width: 34px !important;
  // max-width: 34px !important;
  min-height: 34px !important;
  // height: 34px !important;
  // max-height: 34px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

body #CookiebotWidget:hover {
  background: $opacity !important;
}

body #CookiebotWidget:hover .CookiebotWidget-logo svg circle {
  fill: transparent !important;
}

body #CookiebotWidget:not(.CookiebotWidget-open) button.CookiebotWidget-logo svg {
  min-width: 30px !important;
  width: 30px !important;
  max-width: 30px !important;
  min-height: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
}

body #CookiebotWidget .CookiebotWidget-logo svg circle {
  fill: $secondary !important;
}

body #CookiebotWidget .CookiebotWidget-logo svg path {
  fill: $primary !important;
}

body .CybotCookiebotFader {
  background-image: none !important;
}

@media screen and (min-width: 601px) {
  body #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type) {
    border-right: 1px solid $dark !important;
  }
}



// Sections

.sectionPage,
.pageSingleBlog {
  padding: 80px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerPage {
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionsPages {
  padding: 110px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containersPages {
  width: 100%;
  max-width: 1340px;
  display: flex;
  flex-direction: column;
}

.sectionInsidePageMargin {
  margin: 70px 0px;
  width: 100%;
}

.sectionInsidePageLittleMargin {
  margin: 40px 0px 70px;
  width: 100%;
}

.sectionInsidePageMarginTop {
  margin: 70px 0px 0px;
  width: 100%;
}

.sectionInsidePageMarginBottom {
  margin: 0px 0px 70px;
  width: 100%;
}

.sectionIframeTheme {
  background-color: $secondary;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.separatorSections {
  width: 100%;
  height: 1px;
  background-color: $dark;
  margin: 70px 0px;
}

.separatorSectionsMini {
  width: 100%;
  height: 1px;
  background-color: $dark;
  margin: 20px 0px;
}

.separatorSectionsMarginTop {
  margin-bottom: 0px;
}


// Cards
.cardsPages {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: $dark;
  border: 1px solid $dark !important;
  border-radius: 5px;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 20px;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.titleSubPages {
  margin-bottom: 40px;
  text-align: center;
  font-size: 20px;
}

.ressourcesBeely .cardPage {
  border-radius: 5px;
  width: calc(100%/2 - 10px);
  padding: 20px 20px 45px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.legalsDocs .cardPage {
  padding: 22px 45px 22px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardPage h2,
.cardPageTarif h2 {
  font-size: 15px;
}

.cardPage p,
.cardPageTarif p {
  font-size: 14px;
}

.cardPage .plusPage {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legalsDocs .cardPage .plusPage {
  bottom: 20px;
  right: 20px;
}

.cardPage .plusPage svg {
  width: 20px;
}

.cardPage,
.cardPageTarif {
  background-color: $terciary;
  border-radius: 5px;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid transparent;
}

.cardPage:not(.cardService) {
  padding: 20px;
}

.card:hover {
  box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
  // box-shadow: rgb(0 0 0 / 50%) 0px 4px 20px;
}

.cardPage:hover,
.cardPageTarif:hover {
  box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
  // box-shadow: rgb(0 0 0 / 50%) 0px 4px 20px;
  border: 1px solid $dark !important;
  background-color: $opacity;
}

.headerCardPage {
  background-color: $dark;
  border: 1px solid $dark;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: 20px;
  border: none;
}

@media (max-width: 778px) {
  .cardsPages:not(.linksDashboardBeely .cardsPages):not(.footerBottom .cardsPages) {
    flex-direction: column;
  }

  .ressourcesBeely .cardPage {
    border-radius: 5px;
    width: 100%;
  }
}

// LoaderPages

.loaderPages {
  background-color: $purple-transparent;
}

.loaderDashboard,
.loaderPages {
  background-color: $secondary;
}

// Loader

.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 0px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Header 

.header {
  background-color: $dark-transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid $purple-transparent;
}

.logoHeaderDashboard {
  width: 100px;
  margin-top: 5px;
}

.logoHeader,
.logoHeaderDashboard {
  filter: brightness(0) invert(1);
}

.header .megaMenu {
  background-color: $secondary;
  border: 1px solid $dark;
  overflow: hidden;
}

.header .megaMenu li .iconInsideMegaMenu {
  width: 20px;
  font-size: 16px;
  margin-right: 10px;
  color: $cream;
  background: $purple;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .megaMenu li .iconInsideMegaMenu svg {
  width: 20px;
}

.header .navigation .navigationList:hover .navigationLink {
  background-color: $purple-transparent;
  border-radius: 5px;
}

.linkHeaderMegaMenu {
  border-bottom: 1px solid $purple-transparent;
}

.linkHeaderMegaMenu:hover {
  background-color: $purple-transparent;
}

.miniBtn.userDashboard {
  display: none;
}

.actionHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.headerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.sectionButtonMenuBurger {
  display: none;
}

.buttonMenuBurger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1110;
  background: transparent;
  border: 1px solid $dark;
}

.buttonMenuBurger:hover {
  background: $opacity;
}

.buttonMenuBurger svg {
  color: $primary;
  width: 20px;
}

.sectionMenuMobile {
  background-color: $secondary;
}

.navigationMobile .navigationLink span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
}

.navigationMobile .megaMenu {
  border-left: 2px solid $opacity !important;
  border-radius: 0px;
}

.questionsHeaderMobile {
  background-color: $purple-transparent;
}

@media (min-width: 961px) {
  .sectionMenuMobile {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .logoHeader.logoDesktop {
    display: none;
  }

  .logoHeader.logoMobile {
    display: block;
  }

  .header ul.navigation {
    display: none;
  }

  .headerRight {
    gap: 10px;
  }

  .sectionButtonMenuBurger:not(.topHeaderLeftTop .sectionButtonMenuBurger) {
    display: flex;
    order: 2;
  }
}

@media (max-width: 778px) {

  .header,
  .containerHeader {
    height: 55px;
  }

  .actionHeader:not(.headerTopHelp .actionHeader) .button2 {
    display: none;
  }

  .actionHeader:not(.headerTopHelp .actionHeader) .button1 {
    padding: 9px 10px !important;
    min-height: 30px !important;
    gap: 7px !important;
    font-size: 12px !important;
  }

  .actionHeader:not(.headerTopHelp .actionHeader) .button1 span {
    font-size: 12px !important;
  }

  .actionHeader:not(.headerTopHelp .actionHeader) .button1 svg {
    min-width: 14px !important;
    width: 14px !important;
    max-width: 14px !important;
  }

  .miniBtn.userDashboard {
    display: flex;
  }

  .actionHeaderMobile {
    flex-wrap: wrap;
    gap: 15px;
  }
}



// Footer

.footerTop {
  background-color: $purple-transparent;
}

.footerBottom {
  // background-color: $purple-transparent;
  border-top: 1px solid #4c234b;
}

.logoFooter,
.logoCopyright,
.logoHeaderLeftDashboard {
  filter: brightness(0) invert(1);
}

.footerSocials a {
  background-color: transparent;
  border: 1px solid $dark;
}

.footerSocials a svg {
  color: $purple;
}

.footerSocials a:hover {
  background: $opacity;
}

#scrollTopHeaderArrow {
  background-color: $secondary;
  border: 1px solid $dark;
  color: $purple;
  cursor: pointer;
}

#scrollTopHeaderArrow:hover {
  background: $opacity;
}

.copyright {
  // background-color: $purple-transparent;
  border-top: 1px solid #4c234b;
}


// Forms

.popUpForm {
  background-color: $dark-transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 80px;
  right: 20px;
  bottom: 20px;
  height: calc(100vh - 100px);
  width: 450px;
  overflow-y: scroll;
  border-radius: 5px;
  z-index: 100;
}

.headerForm {
  background-color: $purple;
  padding: 20px;
  padding: 20px;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerForm svg {
  width: 24px;
}

.descriptionForm {
  padding: 40px 20px 20px;
}

.upbeeForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.topForm {
  display: flex;
  gap: 20px;
}

input:not(.inputSelectTheme input):not(.inputSearchBar) {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 0px 10px;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.sectionContactForm form label input:not(input[type=radio]),
.sectionContactForm form textarea {
  color: $white;
}

.sectionProfile input {
  width: 100%;
  height: 20px !important;
  border-radius: 0px !important;
  margin-top: 10px;
}

.inputSearchBar {
  width: 100%;
  height: 40.5px !important;
}

textarea {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  margin-top: 10px;
}

.label50 {
  width: 50%;
}

.label100 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.popUpForm .button1 {
  width: 100%;
}


// Skeleton

.skeleton,
.skeletonSites .sectionSites {
  background-color: $opacity;
  // border: 1px solid $purple;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

// Notices

.closeNotice {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noticeBeely {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  padding: 10px 20px;
  color: white;
  margin-bottom: 30px;
  background-color: $secondary;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.noticeBeely svg {
  width: 20px;
}

.noticeError {
  background: red;
}



// Theme Wrapper

.loadingForm .loaderForm {
  color: $purple;
}

.btnSizeCanvaTheme {
  background: $purple;
  color: $white;
}

.btnSizeCanvaTheme.btnCloseCanvaTheme {
  background: $pink;
}

.btnSizeCanvaTheme.btnCloseCanvaTheme svg {
  color: $purple;
}

.themeWrapper .switch {
  border: 1px solid $dark;
}

.themeWrapper .switch:hover {
  background: $opacity;
}

.moonTheme {
  color: $primary;
  width: 20px;
  display: none;
}

.lightTheme {
  & .moonTheme {
    display: block;
  }

  ;
}

.sunTheme {
  color: $primary;
  display: block;
  width: 20px;
}

.lightTheme {
  & .sunTheme {
    display: none;
  }

  ;
}


// Heros

.heroPages {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
}

.heroPages h1 {
  font-size: 36px;
}

.heroPages .buttonHero {
  height: 44px;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

@media (max-width: 778px) {

  .heroPages h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .heroLeftBeely .heroPages h1 {
    margin-bottom: 0px;
  }
}

// Home

.heroLeftBeely h1 b {
  color: $purple;
}

.heroLeftBeely h1 span,
.heroLeftBeely h1 b span {
  color: $white;
}

.heroRightBeely .scroller img {
  border-color: $purple;
}

.subHeroLeft .button1,
.subHeroRightTop .button1,
.subHeroRightBottom .button1 {
  background-color: white;
}

.subHeroLeft .button1 span,
.subHeroRightTop .button1 span {
  color: black;
}

.iconViewThemes {
  color: white;
}

.arrowDown svg {
  color: white;
}

.advantagesBeely .advantage .iconCheckHero {
  color: white;
}

button.btnSteps {
  background: $opacity;
  border: 1px solid transparent;
}

button.btnSteps:hover {
  border: 0px solid $primary;
}

.plusSteps {
  background-color: $primary;
}

.sectionStepLeft {
  background: $opacity;
}

// Home / DifferentOffers

.differentOffers {
  background-color: $purple-transparent;
}

.websiteOffer {
  border: 1px solid $purple;
}

.websiteOffer .cardTop {
  background-color: $purple;
}

.websiteOffer .cardBottom {
  background: $terciary;
}

.websiteOffer .cardBottom .advantageOffer {
  background-color: $purple;
  color: $white;
}

.websiteOffer .cardBottom .toggleSpecs {
  color: $white;
  border-bottom: 1px solid $purple-transparent;
}

.websiteOffer .cardBottom .toggleSpecs svg {
  color: $purple;
}

// Home / FormTypeSite

.anchorLink {
  position: relative;
  top: -50px
}

.whatOffer .formTypeSite {
  border-color: $dark;
}

details {
  padding: 0px !important;
}

details summary {
  padding: 8px 10px;
}

details.cardPageTarif summary {
  padding: 15px;
}

details:not(details[open]).cardPage {
  padding: 0px;
}

details:not(details[open]).cardPage h4 {
  margin-bottom: 0px;
}

summary {
  list-style: none;
  outline: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

summary::-webkit-details-marker {
  display: none;
}

summary::before {
  content: "›";
  position: absolute;
  right: 15px;
  top: 5.5px;
  font-size: 20px;
}

details.cardPageTarif summary::before {
  top: 13px;
}

details[open] summary::before {
  transform: rotate(90deg);
}

details p {
  padding: 0px 10px 10px;
  font-size: 12px !important;
}

details.cardPageTarif p {
  padding: 0px 15px 15px;
  font-size: 14px !important;
}

// Home / TableOffers

.tableBeely.tableDesktopBeely .titleHeaderTitleBeely {
  color: $purple !important;
}

.tableDesktopBeely .sectionTableHeaderBeely:nth-child(2) {
  border-top: 1px solid $dark;
  border-right: 1px solid $dark;
  border-left: 1px solid $dark;
  background: $terciary;
}

.pushShow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  padding: 50px 20px 20px 20px;
  cursor: pointer;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(0,0,0,0) 100%);
  margin-top: -50px;
  color: $black !important;
  z-index: 90;
  position: relative;
}

.pushShow.pushShowing {
  background: none !important;
  margin-top: 0px !important;
  padding: 20px !important;
}

.pushShow svg {
  width: 16px;
}

// .tableMobileBeely .sectionTableHeaderBeely {
//   border: 1px solid $dark;
//   background: $terciary;
// }

.recommandedNotice {
  background: $terciary;
  border: 1px solid $dark;
}

.headerTableStickyBeely {
  background-color: $secondary;
  border-bottom: 1px solid $dark;
}

.tableBodyHeaderBeely {
  background-color: $without-opacity;
}

.tableBodyHeaderBeely .iconTable {
  background: $primary;
}

.tableDesktopBeely .tableBodyTdBeely:nth-child(2) {
  border-right: 1px solid $dark;
  border-left: 1px solid $dark;
  background: $terciary;
}

.tableBodyBeely .tableBodyTrBeely:not(.tableBodyBeely .tableBodyTrBeely:last-child) {
  border-bottom: 1px solid $dark;
}

.tableBodyTdBeely svg {
  color: $primary;
}

.tableBodyTdBeely svg.tableCross {
  color: rgba(255, 0, 0, 0.5);
}

.headerTablesMobileBeely {
  border-top: 1px solid $without-opacity;
  border-bottom: 1px solid $without-opacity;
  background-color: $without-opacity;
}

.buttonHeaderTableBeely {
  background-color: $secondary;
}

.selectorHeaderTableBeely {
  background-color: $primary;
}

// Home - Templates

.btnPrev,
.btnNext {
  background-color: $purple;
}

// Home - FAQ

.leftFaqHome {
  background-color: $purple;
}

.bottomLeftFaqHome {
  background-color: $purple;
}

.rightFaqHome {
  background-color: #4c234b;
}

// Tarifs

.headerStickyServices {
  background: $secondary;
  border-bottom: 1px solid $dark;
}

.avantagesServices .avantageService {
  border: 1px solid $primary;
  background: $opacity;
}

.socle {
  background: $opacity;
}

.socleData {
  background: $opacity;
  border-radius: 20px;
  // box-shadow: 5px 5px 10px 1px $opacity;
}

.buttonDownTarifs {
  border: 1px solid $dark;
}

.buttonDownTarifs:hover {
  background-color: $opacity;
}

.descriptionServicePage .rightDescriptionServicePage img {
  border: 3px solid $black-p;
}

// Templates

.closeIframe {
  color: white;
}

.cardTheme {
  background-color: $opacity;
  border: 1px solid $dark;
}

.cardTheme {
  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  animation: smooth .5s ease-out forwards;
  overflow: hidden;
}

.cardTheme img {
  height: 100%;
}

.contentCardTheme {
  padding: 15px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btnsCardTheme {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.btnsCardTheme .button1 {
  text-align: center;
}

.topIframeTheme {
  background: $dark;
}

.topIframeTheme svg,
.topIframeTheme .closeIframe svg {
  color: $white !important;
}

#themeIframe {
  background: $without-opacity;
}

@media (max-width: 576px) {
  .btn {
    display: block;
    margin: 0 auto;
  }
}

// Help

.categorieHelp:hover,
.btnCategorieHelp ul li .linkArticleHelp:hover {
  background: $without-opacity;
}

.containerContactHelp {
  background: $without-opacity;
  border: 1px solid $dark;
}

// Blog

.buttonCategorie {
  background: $dark;
  border: 1px solid transparent;
  color: $white;
}

.informationCard {
  border-color: #00b37a;
  background: #00b37a10;
}

.informationCard svg,
.informationCard span,
.informationCard p {
  color: #00b37a !important;
}

// .buttonCategorie:not(.megaMenuThemes .buttonCategorie):not(.sidebarThemes .buttonCategorie) {
//   box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
//   border: 1px solid $dark;
// }

.postBlogHelp {
  background-color: $terciary;
  border: 1px solid transparent;
}

.postBlogHelp:hover {
  box-shadow: rgb(90 105 120 / 20%) 1px 1px 20px 0px;
  border: 1px solid $dark;
}

.tagBlogHelp {
  background: $primary;
}

input.inputSearchBar {
  background-color: $opacity;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid $dark;
  color: $white;
}

.searchBar form button {
  background: $purple;
}

// Ressources

.cardPage {
  // border: 2px solid $purple-transparent;
}

.cardPage .plusPage {
  background: $purple;
}

// Faq

.tabTitle {
  background-color: $purple-transparent;
}

.faqHome .tabTitle {
  background-color: $secondary;
}

.buttonTabFaq {
  background-color: transparent;
}

.buttonTabFaq:hover {
  background-color: $purple-transparent;
}

.buttonTabFaq svg {
  color: $purple;
  transition: .1s;
}

.buttonTabFaq:hover svg {
  color: $white;
}

.sectionFaqTabs:not(.faqHome .sectionFaqTabs) {
  background: $secondary;
}

// Glossary

.buttonsTabsGlossary {
  background-color: $purple-transparent;
}

.buttonTabGlossary {
  background-color: transparent;
}

.buttonTabGlossary:hover {
  background-color: $purple;
}

// 404

.sectionPage404 {
  background-color: $opacity;
  display: flex;
}

.sectionPage404 .sectionLeft404 {
  width: 50%;
  min-height: 100vh;
  background-color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.sectionPage404 .sectionLeft404 h1 {
  font-size: 30px;
}

.sectionPage404 .sectionLeft404 h2 {
  font-size: 200px;
}

@media (max-width: 1024px) {
  .sectionPage404 {
    flex-direction: column;
  }

  .sectionPage404 .sectionLeft404:nth-child(1) {
    width: 100%;
    padding: 50px 20px;
  }
}

@media (max-width: 500px) {
  .sectionPage404 .sectionLeft404:nth-child(1) {
    gap: 10px;
  }

  .sectionPage404 .sectionLeft404 h2 {
    font-size: 100px;
    margin-bottom: 10px;
  }
}





// DASHBOARD

// Sections

.sectionPageDashboard {
  position: relative;
  z-index: 1;
  // background-color: $secondary;
  background-color: $terciary;
  // background-color: #371a3f;
  padding: 90px 20px 20px 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.sectionPageDashboardOrder {
  position: relative;
  z-index: 1;
  background-color: $secondary;
  padding: 70px 0px 0px 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sectionDashboard {
  background-color: $dark;
  border: 1px solid $purple;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  width: 100%;
  border-radius: 5px;
  padding: 50px;
}

.separatorSectionDashboardVertical {
  min-height: calc(100% - 100px);
  width: 1px;
  background-color: rgb(54, 54, 54);
  margin: 70px 0px;
}

.sectionPageDashboard.sectionPageDashboardRecipes {
  align-items: flex-start;
  justify-content: flex-start;
}

.paragrapheDashboard {
  text-align: left;
  width: 100%;
}

.sectionPageRecipes {
  display: flex;
  width: 100%;
  margin-top: 40px;
}



.linksDashboardBeely .cardPage.toDoList input[type='text'],
.linksDashboardBeely .cardPage.cardNotes input[type='text'] {
  border-bottom: 1px solid white;
  color: white;
}

.linksDashboardBeely .cardPage.cardNotes textarea {
  border-bottom: 1px solid white;
  color: white;
}

@media (max-width: 791px) {
  .sectionPageDashboard {
    padding: 80px 20px 20px;
  }

  .sectionPageDashboardOrder {
    padding: 60px 0px 0px;
  }
}

// Header

.headerTopDashboard,
.headerLeftDashboard {
  background-color: $secondary;
  z-index: 100;
}

.headerTopDashboard,
.stickyHeaderLeftDashboard {
  background-color: $dark-transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.headerLeftDashboardDesktop {
  border-right: 1px solid $dark;
}

.button2.logOutButton {
  background-color: transparent;
}

.button2.logOutButton:hover {
  background-color: $opacity;
}

.button2.logOutButton svg {
  color: $primary;
}

.profileHeaderDashboard.headerLeft,
.descriptionHeaderLeft {
  background: $opacity;
}

.headerLeftDashboard .closeMenuMobile {
  color: $primary;
}

.headerLeftDashboard svg {
  color: $white;
}

.headerLeftDashboard .linksDashboard:hover {
  background-color: $opacity;
  color: white;
  border-radius: 5px;
}

.separatorHeaderDashboard {
  width: 100%;
  min-height: 1px;
  background-color: $dark;
  margin: 10px 0px;
}

.button2.logOutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 34px;
  height: 34px;
}

// Login/Register/ResetPassword

.sectionPageAccountLeft {
  background-color: $dark;
}

.sectionPageAccountRight {
  background-color: $secondary;
}

.sectionPageAccountRight form input {
  border-bottom: 1px solid grey;
  color: white;
}

.divFormProfile input {
  background-color: transparent;
  color: white;
}

// Dashboard

.sectionLinksDashboard .titleSubPages,
.linksDashboardBeely.servicesDashboard .titleSubPages {
  background: $purple-transparent;
  font-family: 'Plus Jakarta Sans', sans-serif;
  ;
}

.linksDashboardBeely .cardPage {
  background: $opacity;
}

.linksDashboardBeely .cardPage.toDoList input[type='text'],
.linksDashboardBeely .cardPage.cardNotes input[type='text'],
.linksDashboardBeely .cardPage.cardNotes textarea {
  border-bottom: 1px solid $primary;
}

.sectionTitleDashboard .button1 {
  display: flex;
  gap: 10px;
  padding: 10px 10px 10px 5px;
  min-width: 185px;
}

.sectionTitleDashboard .button1 svg {
  width: 20px;
}

// Profile

.sectionProfile {
  background-color: $opacity;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.sectionAboutProfile,
.sectionSecurityProfile {
  background-color: $purple-transparent;
}

.divFormProfile:not(.divFormProfile.lastInput),
.divFormSecurityProfile {
  border-bottom: 1px solid $dark;
  ;
}

@media (max-width: 450px) {
  .sectionProfile {
    background: transparent;
  }
}

// Order

.returnDashboard {
  background-color: $purple-transparent;
}

.switchPrices {
  background-color: $dark;
  border-bottom: 1px solid $dark;
}

.switchPrice {
  background: $secondary;
}

.selectorHeaderOrderBeely {
  background-color: $primary;
}

.orderThemes,
.formOrders {
  background-color: $opacity;
}

.orderThemes {
  display: flex;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  max-width: 1340px;
}

.orderThemes .infoOrderThemes svg {
  width: 20px;
}

.orderThemes .infosOrderThemes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.questionForm {
  border-left: 3px solid white;
}

.formOrders input:not(.inputSelectTheme input),
.formOrders textarea {
  border-bottom: 1px solid white;
  color: white;
}

.inputSelectTheme p,
.labelInputWhatOffer p {
  background: $dark;
  color: $white;
  border: 1px solid $primary;
}

.inputSelectTheme input:checked+p,
.labelInputWhatOffer input:checked+p {
  background: $primary;
  color: $white;
}

input#legals {
  border: 1px solid white;
}

input#legals:checked {
  background: #1e69fe;
}

@media (max-width: 1024px) {
  .formPrice {
    background: $dark;
    // box-shadow: 5px 5px 10px 10px #00000034;
  }
}

// MySites

.cardMySiteBeely {
  background-color: $opacity;
  position: relative;
}

// Recipes

table {
  background: $opacity;
  border: 1px solid $dark;
  overflow: hidden;
  box-sizing: border-box;
}

table thead,
table tbody td {
  border-bottom: 1px solid $dark;
}

table tbody tr:nth-child(odd) {
  background: $dark;
}

table thead {
  border-radius: 10px 10px 0px 0px;
}

.metasRecipe {
  background-color: $dark;
  border: 1px solid $purple;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

// Support

.sectionSupport {
  background-color: $secondary;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.error {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.success {
  background: rgb(0, 93, 0) !important;
}

.sectionSupport form .radioSupport label {
  background: $dark;
  border: 1px solid $primary;
}

.sectionSupport form .radioSupport label p {
  color: $white;
}

.sectionSupport form label input:not(input[type=radio]),
.sectionSupport form textarea,
input,
textarea {
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: $purple-transparent !important;
  padding: 0px 10px;
}

.checked {
  background-color: $primary !important;
}

.checked p {
  color: $white !important;
}

@media (max-width: 450px) {
  .sectionSupport {
    background: transparent;
  }
}