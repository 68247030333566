.themeWrapper {
  z-index: 11;
  width: 35px;
  height: 35px;
  display: none;
}
 
.header .themeWrapper {
  margin-right: 10px;
}

.themeWrapper .switch {
  width: 34px;
  height: 34px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.themeWrapper .switch input {
  display: none;
}

.themeWrapper .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .4s;
  border-radius: 5px;
  z-index: 100;
  overflow: hidden;
}

// Responsive

@media (max-width: 960px) {
.header .themeWrapper {
  margin: 0px;
  order: 1;
  }
}