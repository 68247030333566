.sectionPageBlog {
    padding: 170px 20px 0px;
}

.pageBlog {
    width: 100%;
    max-width: 1340px;
    margin: 0px auto 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageBlog.skeleton-categories-posts {
    margin-bottom: 0px;
}

.sectionSinglePost.sectionPage,
.skeletonSingleBlog.pageSingleBlog {
    padding-top: 170px;
}

.buttonsCategories {
    display: flex;
    flex-wrap: wrap;
}

.contentCardTheme .buttonsCategories {
    flex-wrap: nowrap;
}

.buttonsWrapper {
    position: relative;
    overflow: hidden;
    /* Masque le dépassement */
}

/* Gradient pour masquer les éléments à droite */
.buttonsWrapper::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    /* Ajustez la largeur selon le besoin */
    height: 100%;
    pointer-events: none;
    /* Permet de cliquer à travers le gradient */
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    /* Couleur du gradient */
}

.buttonCategorie {
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
}

.cardsCategories {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    text-align: left;
}

.cardPage.cardCategorie {
    width: calc(100%/3 - 13.4px);
    padding: 20px 60px 20px 20px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}

.cardPage.cardCategorie h2 {
    font-size: 16px;
    text-align: left;
}

.plusPageCategory {
    transform: translateY(-50%);
    top: 50%;
}

.postsBlogHelp {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.postBlogHelp {
    width: calc(100%/3 - 13.4px);
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

.postBlogHelp img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.tagBlogHelp {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 2px 15px;
    border-radius: 5px;
    overflow: hidden;
    max-width: calc(100% - 20px);
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.postBlogHelp h3 {
    font-size: 16px;
}

.postBlogHelp .contentCardBlogHelp {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.postBlogHelp .excerptBlogHelp {
    font-size: 14px;
    margin-top: -5px;
    overflow: hidden;
    max-width: calc(100% - 20px);
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.postBlogHelp .btnBlogHelp {
    width: calc(100% - 20px);
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.sectionSinglePost.sectionPage,
.pageSingleBlog {
    margin: auto;
    max-width: 1000px;
}

.sectionSinglePost.sectionPage .pageBlog {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}

.sectionLeftSinglePost {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.sectionLeftSinglePost .tagBlogHelp {
    margin: 0px;
    top: 20px;
    left: 20px;
}

.sectionLeftSinglePost .imgSinglePost {
    border-radius: 5px;
    margin-bottom: 20px;
}

.sectionLeftSinglePost h3,
.containArticleHelp h3 {
    margin: 70px 0px 20px;
}

.sectionLeftSinglePost h4,
.containArticleHelp h4 {
    margin: 70px 0px 20px;
    font-size: 16px !important;
}

.sectionLeftSinglePost p,
.containArticleHelp p:not(.cardResultHelp p) {
    margin: 20px 0px 0px;
    // text-align: justify;
}

.sectionRightSinglePost {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: sticky;
    top: 170px;
}

.containArticleHelp img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}



// RESPONSIVE

@media (max-width: 1024px) {

    .cardPage.cardCategorie,
    .postBlogHelp {
        width: calc(50% - 10px);
    }
}

@media (max-width: 950px) {
    .sectionSinglePost.sectionPage .pageBlog {
        flex-direction: column;
        gap: 50px;
    }

    .sectionLeftSinglePost {
        width: 100%;
    }

    .sectionRightSinglePost {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: unset;
    }
}

@media (max-width: 778px) {

    .cardPage.cardCategorie,
    .postBlogHelp {
        width: 100%;
    }

    .sectionLeftSinglePost h3,
    .containArticleHelp h3 {
        margin: 50px 0px 20px;
    }

    .sectionLeftSinglePost h4,
    .containArticleHelp h4 {
        margin: 40px 0px 20px;
        font-size: 16px !important;
    }
}