// PAGE

.pageThemes .contentCardsThemes {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sidebarThemes {
    width: 100%;
}

.pageThemes .cardsThemes {
    display: inline-flex;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
}

.pageThemes .cardTheme {
    width: calc(100% / 3 - 13.4px);
}

@media (max-width: 800px) {
    .pageThemes .cardTheme {
        width: calc(50% - 10px);
    }
}

@media (max-width: 500px) {
    .pageThemes .contentCardsThemes {
        flex-direction: column;
    }

        .sidebarThemes {
            width: 100%;
        }
        
        .pageThemes .cardTheme {
            width: 100%;
        }
}


// INFORMATION

.informationCard {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 15px;
    border: 0px solid black;
    border-radius: 5px;
    max-width: 550px;
}

.informationCard .headInformationCard {
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: flex-start;
}

.brInformationCard {
    height: 8px;
    width: 100%;
}

.informationCard .headInformationCard .unsetNotice {
    text-decoration: underline;
    cursor: pointer;
    margin-left: auto;
    font-size: 13px;
}

.informationCard svg {
    width: 20px;
}

p.informationTextCard {
    font-size: 13px;
}

p.informationTextCard.informationTextMarginTop {
    margin-top: 5px;
    display: inline-flex;
}


// CATÉGORIES

.buttonsCategories {
    display: flex;
    gap: 7px;
}

// .megaMenuThemes .buttonCategorie,
// .sidebarThemes .buttonCategorie {
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     padding: 5px 10px 5px 10px;
// }

// THÈMES

.cardsThemes {
    display: flex;
    gap: 20px;
}

.cardTheme {
    width: 33%;
}

.imgTheme {
    width: 100%;
    height: 50px;
}

// IFRAMES

.sectionIframeTheme {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.iframeTheme {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    z-index: 110;
}

.topIframeTheme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 10px 15px;
    flex-wrap: nowrap;
    position: relative;
}

.actionHeaderIframeThemes .button1 {
    padding: 7px 10px;
}

.actionHeaderIframeThemes {
    display: flex;
    align-items: center;
    gap: 10px;
}

.actionResponsiveHeaderIframeThemes {
    display: flex;
    gap: 10px;
}

.actionResponsiveHeaderIframeThemes svg {
    width: 20px;
    cursor: pointer;
}

.closeIframe {
    font-size: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.button1.btnRedirectDemo {
    width: 150px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

#themeIframe {
    width: 100%;
    min-height: calc(100vh - 95px);
}

@media (max-width: 1024px) {
    .desktopResponsiveHeaderThemes {
        display: none;
    }
}

@media (max-width: 778px) {
    .actionHeaderIframeThemes {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .actionResponsiveHeaderIframeThemes {
        display: none;
    }
}