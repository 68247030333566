.sectionFaqHome .popUpContactForm,
.sectionsPages .popUpContactForm,
.header .popUpContactForm {
    position: fixed;
    width: 500px;
    top: 20px;
    right: 20px;
    z-index: 99999 !important;
    border-radius: 20px 20px 10px 10px;
}

.sectionContactForm {
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 500px;
    max-width: 500px;
    z-index: 99999999;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 40px);
    padding: 0px 20px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.headercontactform {
    min-height: 70px;
    display: flex;
    width: 500px;
    align-items: center;
    padding: 20px;
    position: sticky;
    top: 0px;
    right: 0px;
    margin-left: -20px;
    border-radius: 10px 10px 0px 0px;
}

.btnCloseContactForm.miniBtn {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 999999999;
    min-width: 30px;
    width: 30px;
    max-width: 30px;
    min-height: 30px;
    height: 30px;
    max-height: 30px;
    cursor: pointer;
}

.sectionContactForm form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
}

.sectionContactForm form .form50 {
    width: 100%;
    display: flex;
    gap: 25px;
}

.sectionContactForm form label input:not(input[type=radio]),
.sectionContactForm form textarea {
    background: transparent;
    font-size: 14px;
    margin: 10px 0 25px;
    -webkit-appearance: none;
    border-radius: 5px;
    height: 50px;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-border-radius: 1;
}

.sectionContactForm form .form50 label {
    width: 50%;
}

.sectionContactForm form textarea {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-top: 20px;
    height: 100px;
    padding: 10px;
}



// Responsive

@media (max-width: 540px) {

    .sectionFaqHome .popUpContactForm,
    .header .popUpContactForm,
    .sectionsPages .popUpContactForm,
    .sectionContactForm {
        max-width: calc(100% - 40px);
    }

    .sectionContactForm {
        max-width: 100%;
    }

    .sectionContactForm,
    .headercontactform {
        width: calc(100% + 40px);
    }
}

@media (max-width: 500px) {

    .sectionFaqHome .popUpContactForm,
    .header .popUpContactForm,
    .sectionsPages .popUpContactForm,
    .sectionContactForm {
        max-width: 100%;
    }

    .sectionContactForm,
    .headercontactform {
        width: 100%;
    }

        .headercontactform {
            width: 100vw;
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -ms-border-radius: 0px;
            -o-border-radius: 0px;
}

.sectionFaqHome .popUpContactForm,
.sectionsPages .popUpContactForm,
.header .popUpContactForm {
    top: 0px;
    right: 0px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.sectionContactForm {
    max-height: 100vh;
}

.btnCloseContactForm.miniBtn {
    top: 20px;
    right: 20px;
}
}